import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import localforage from 'localforage'
// import logo from '../assets/yeti_icon.svg'

// import {
//   BSC,
//   useEthers,
//   useToken,
//   useTokenBalance,
//   useEtherBalance,
//   useContractCalls,
//   useGasPrice,
//   useSendTransaction,
//   useContractFunction
// } from '@usedapp/core'

// import { BigNumber, formatFixed, parseFixed } from '@ethersproject/bignumber'
// import {
//   formatEther,
//   formatUnits,
//   parseEther,
//   parseUnits
// } from '@ethersproject/units'

// import {
//   DisconnectButton,
//   InstallMetaMaskButton,
//   MetaMaskButton,
//   ToBSCButton,
//   WatchYETIButton,
//   WalletConnectButton,
//   ToggleButton,
//   YETIBalance,
//   ConnectWalletButton,
//   HeaderYETIBalance,
//   ToBSCHeaderButton
// } from './dapp'

export default function Header ({ account, setAccount, setToken }) {
  return (
    <header className='z-1 smd:shadow-1 bg-white'>
      <div className='yeti-container container'>
        <div className='yeti-row row py-4 md:py-6'>
          <Main />
          {/* <Wallet account={account} /> */}
          <div className='yeti-col col-4 flex items-center justify-center'></div>
          <Auth account={account} setAccount={setAccount} setToken={setToken} />
        </div>
      </div>
    </header>
  )
}

function Main () {
  return (
    <div className='yeti-col col-4 flex items-start justify-start'>
      <Link
        className='yeti-link flex items-center justify-center cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker'
        to='/'
      >
        {/* <img
          src={logo}
          className='headerlogo flex items-center justify-center '
          alt='logo'
        /> */}
        <span className='hidden lg:inline '>YETI VPN Premium</span>
      </Link>
    </div>
  )
}

// function Wallet ({ account }) {
//   const [installed, setInstalled] = useState(false)
//   const [connectorName, setConnectorName] = useState('')
//   const [hideButtons, setHideButtons] = useState(false)
//   const [walletAddress, setWalletAddress] = useState(null)
//   const [allowAutoHide, setAllowAutoHide] = useState(null)

//   // const ethers = useEthers()
//   // const { account, chainId, library, error } = ethers

//   useEffect(() => {
//     if (window.ethereum) {
//       setInstalled(true)
//     }
//   }, [])

//   if (!account || !installed)
//     return (
//       <div className='yeti-col col-4 flex items-center justify-center'></div>
//     )

//   return (
//     <div className='yeti-col col-4 flex items-center justify-center'>
//       <div className='header-wallet row'>
//         <div className='header-balance'>
//           <HeaderYETIBalance />
//         </div>
//         <ConnectWalletButton
//           installed={installed}
//           setAllowAutoHide={setAllowAutoHide}
//           ethers={ethers}
//           setConnectorName={setConnectorName}
//         />
//         <ToBSCHeaderButton ethers={ethers} />
//         <ToggleButton
//           hideButtons={hideButtons}
//           setHideButtons={setHideButtons}
//           setAllowAutoHide={setAllowAutoHide}
//           ethers={ethers}
//         />
//       </div>
//     </div>
//   )
// }

function Auth ({ account, setAccount, setToken }) {
  return (
    <div className='yeti-col col-4 flex items-center justify-end'>
      <p className='yeti-text text-small leading-normal text-grey font-medium select-none'>
        <span className='hidden lg:inline'>
          {account ? account.email : 'Already have Yeti Account?'}
        </span>
        {account ? (
          <Link
            className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker text-blue leading-loose ml-3'
            to='/login'
            onClick={async e => {
              setAccount(null)
              setToken(null)
              try {
                await localforage.removeItem('token')
              } catch (err) {
                console.log('save token error', err)
              }
            }}
          >
            Log out
          </Link>
        ) : (
          <Link
            className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker text-blue leading-loose ml-3'
            to={'/login'}
          >
            Log in
          </Link>
        )}
      </p>
    </div>
  )
}
