import React, { useEffect, useState } from 'react'
import ReactSlider from 'react-slider'
import SelectDot from './SelectDot'
// import { PancakeRates } from './Yeti'

export function CustomPlan (props) {
  const {
    showBest,
    plans,
    selectedPlanID,
    setSelectedPlanID,
    selectedPlan,
    purchaseMonths,
    setPurchaseMonths
  } = props

  let months = purchaseMonths

  useEffect(() => {
    if (plans && plans.custom) setPurchaseMonths(plans.custom.monthsDefault)
  }, [plans])

  switch (selectedPlanID) {
    case 'month':
      // setPurchaseMonths(1)
      months = 1
      break
    case 'year':
      months = 12
      break
    case '2years':
      months = 24
      break

    default:
      months = purchaseMonths
      break
  }

  // const { BNB, YETI, priceBNB, priceYETI } = PancakeRates({ selectedPlan, purchaseMonths })
  // const offerPriceYETI = priceYETI * 0.6
  // const monthlyPriceYETI = (offerPriceYETI / months).toFixed(2)

  if (!plans || !selectedPlanID) return null

  const plan = plans.custom

  // console.log('plan', plan, plans)
  const selected = selectedPlanID === plan?.id
  // const savePercent = plan.monthSave * (purchaseMonths - 1)
  let savePercent = 0
  for (let i = 0; i < purchaseMonths; i++) {
    savePercent += plan.monthSave[i]
  }
  // const resultPrice = 1 * purchaseMonths * Math.E^(-purchaseMonths/24)
  const resultPrice =
    (plan.priceMonthUSD * purchaseMonths * (100 - savePercent)) / 100

  const fullPrice = plan.priceMonthUSD * purchaseMonths
  const monthlyPrice = (resultPrice / purchaseMonths).toFixed(2)

  let selectedFull = fullPrice
  let selectedResult = resultPrice
  if (!selected) { 
    selectedFull = selectedPlan.priceFull && +selectedPlan.priceFull.slice(1) || +selectedPlan.price.slice(1)
    selectedResult = +selectedPlan.priceResult.slice(1)
  }




  return (
    <div className='yeti-container container'>
      <div className='mt-1 mb-3 sm:mb-3 md:mb-3 lg:mt-1 lg:mb-5'>
        <div className='yeti-row row'>
          <div className={'yeti-col col-12 md:col-12 lg:col-4 flex flex-col ' + (showBest && plan.best ? '' : 'mb-3')}>
            <button
              type='button'
              className={
                'focus:outline-none flex w-full px-3 py-6 md:px-4 text-grey-darkest rounded-2 border-2 shadow-1 bg-white border-white transition-all duration-250 ease-out  text-left flex-auto ' +
                (selected ? 'border-blue' : '')
              }
              onClick={() => {
                setSelectedPlanID(plan.id)
              }}
            >
              <SelectDot selected={selected} />
              <div className='flex flex-col justify-between self-stretch w-full font-medium'>
                <div>
                  <div className='flex flex-wrap justify-between items-center'>
                    <div className='text-base lg:text-lead mb-2 mr-2'>
                      {plan.title} | {purchaseMonths}{' '}
                      {purchaseMonths > 1 ? 'months' : 'month'}
                    </div>
                    <div>
                      <div
                        className={
                          'px-3 py-1 rounded-3 inline-block text-micro font-medium transition-colors duration-250 ease-out mb-2 ' +
                          (selected
                            ? 'bg-blue text-white'
                            : 'bg-grey-lighter text-grey-darker')
                        }
                      >
                        Save {savePercent}%
                      </div>
                    </div>
                  </div>
                  <div data-testid='ProductCard__price' className='mb-2'>
                    <span data-testid='ProductPrice' className='price'>
                      <span className='yeti-text text-base leading-normal text-h4 leading-loose'>
                        ${monthlyPrice}
                      </span>
                    </span>
                    <span className='text-h4 lg:text-base'>/mo</span>
                  </div>
                  <div className='purchase-slider'>
                    <ReactSlider
                      marks
                      className='horizontal-slider'
                      thumbClassName='purchase-thumb'
                      trackClassName='purchase-track'
                      markClassName='purchase-mark'
                      min={0}
                      max={23}
                      value={purchaseMonths - 1}
                      defaultValue={plan.monthsDefault - 1}
                      onBeforeChange={(value, index) => {
                        if (!selected) {
                          setSelectedPlanID(plan.id)
                        }
                        console.log(
                          `onBeforeChange: ${JSON.stringify({
                            value,
                            index
                          })}`
                        )
                      }}
                      onChange={(value, index) => {
                        console.log(
                          `onChange: ${JSON.stringify({ value, index })}`
                        )
                        setPurchaseMonths(value + 1)
                      }}
                      onAfterChange={(value, index) =>
                        console.log(
                          `onAfterChange: ${JSON.stringify({ value, index })}`
                        )
                      }
                      renderTrack={(props, state) => <div {...props} />}
                      renderThumb={(props, state) => (
                        <div {...props}>{state.valueNow + 1}</div>
                      )}
                      renderMark={props => <div {...props} />}
                    />

                    {/* <ResizableSlider purchaseMonths={purchaseMonths} setPurchaseMonths={setPurchaseMonths}/> */}
                  </div>
                </div>
                <p className='text-micro font-medium text-grey-dark'>
                  {plan.priceMonthUSD ? (
                    <del>${fullPrice.toFixed(2)}</del>
                  ) : null}
                  <span className='text-blue'>${resultPrice.toFixed(2)}</span>{' '}
                  {plan.periodDesc}
                  <span className='text-small' data-testid='IntroductorySymbol'>
                    *
                  </span>
                </p>
              </div>
            </button>
          </div>



          {/* <div className='yeti-col col-12 md:col-12 lg:col-4 flex flex-col mb-3'>
            <button
              type='button'
              id='yeti-offer'
              className={
                'focus:outline-none flex w-full px-3 py-6 md:px-4 text-grey-darkest rounded-2 border-2 shadow-1 bg-white border-white transition-all duration-250 ease-out  text-left flex-auto border-red'
              }
              onClick={() => {
                // setSelectedPlanID(plan.id)
              }}
            >
              <div className='flex flex-col justify-between self-stretch w-full font-medium yeti-offer-text'>
                <div>
                  <div className='flex flex-wrap justify-between items-center'>
                    <div className='text-base lg:text-lead mb-2 mr-2'>
                      LIMITED TIME OFFER
                    </div>
                    <div>
                      <div
                        className={
                          'px-3 py-1 rounded-3 inline-block text-micro font-medium transition-colors duration-250 ease-out mb-2 bg-red text-white'
                        }
                      >
                        Save 40% More
                      </div>
                    </div>
                  </div>
                  <div className='text-base lg:text-base mb-2 mr-2'>
                    Pay with{' '}
                    <a
                      className='yeti-link cursor-pointer outline-none transition-colors duration-250 ease-out text-grey-dark hover:text-grey-darker text-blue leading-loose'
                      target='_blank'
                      rel='noreferrer'
                      href='https://yeticoin.tech/'
                    >
                      YETI Coin
                    </a>{' '}
                    and save 40% more!
                  </div>
                  <div data-testid='ProductCard__price' className='mb-2'>
                    <span data-testid='ProductPrice' className='price'>
                      <span className='yeti-text text-base leading-normal text-h4 leading-loose'>
                        {monthlyPriceYETI} YETI
                      </span>
                    </span>
                    <span className='text-h4 lg:text-base'>/mo</span>
                  </div>
                </div>
                <p className='text-micro font-medium text-grey-dark'>
                  {' '}
                  {offerPriceYETI ? (
                    <del>{priceYETI.toFixed(2)}</del>
                  ) : null}
                  <span className='text-blue'>
                    {+(offerPriceYETI).toFixed(4)} YETI
                  </span>{' '}<span >
                    (~${(selectedResult * 0.6).toFixed(2)})
                  </span>{' '}
                  {plan.periodDesc}
                  <span className='text-small' data-testid='IntroductorySymbol'>
                    *
                  </span>
                </p>
              </div>
            </button>
          </div> */}

          <div className='yeti-col col-12 md:col-12 lg:col-4 flex flex-col mb-3'>
            <button
              type='button'
              className={
                'focus:outline-none flex w-full px-3 py-6 md:px-4 text-grey-darkest rounded-2 border-2 shadow-1 bg-white border-white transition-all duration-250 ease-out  text-left flex-auto'
              }
              onClick={() => {
                // setSelectedPlanID(plan.id)
              }}
            >
              <SelectDot selected={false} />
              <div className='flex flex-col justify-between self-stretch w-full font-medium yeti-offer-text'>
                <div>
                  <div className='flex flex-wrap justify-between items-center'>
                    <div className='text-base lg:text-lead mb-2 mr-2'>
                      Your Private Server
                    </div>
                    <div>
                      <div
                        className={
                          'px-3 py-1 rounded-3 inline-block text-micro font-medium transition-colors duration-250 ease-out mb-2 bg-grey-lighter text-grey-darker'
                        }
                      >
                        COMING SOON
                      </div>
                    </div>
                  </div>
                  <div className='text-base lg:text-base mb-2 mt-4 mr-2'>
                    Server in selected location exclusively for you! 
                  </div>
                  <div className='text-base lg:text-lead mb-2 mt-4 mr-2'>
                    Maximum speed and stability!
                  </div>
                  <div data-testid='ProductCard__price' className='mb-2'>
                    <span data-testid='ProductPrice' className='price'>
                      {/* <span className='yeti-text text-base text-h5 leading-loose'>
                        COMING SOON
                      </span> */}
                    </span>
                  </div>
                </div>

              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
