import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as requests from '../requests'

export default function CancelSub(props) {
  const [reason, setReason] = useState('')
  const navigate = useNavigate()
  const { token, account, setAccount, onConfirm, onCancel } = props
  if (!account && !account.subStatus) return null

  const endDate = account.subStatus.nextPaymentTime || '01.01.2021'
  const subId = account.paymentInfo.id

  return (
    <div className='yeti-container container text-black flex-grow flex-shrink-0 px-10'>
      <div className='Card2 smd:shadow-1 smd:rounded bg-white smd:pb-6 smd:pt-8 mx-auto mt-6 smd:mt-12 smd:px-6 flex flex-col p-6'>
        <form>
          <fieldset className='min-w-0'>
            <h2 className='yeti-text text-h4 leading-normal font-medium tracking-tight mb-6 text-center'>
              Are you sure?
            </h2>
            <div className='font-medium leading-normal w-full mt-6 mb-6 text-center'>
              Your current subscription will be canceled, but you&apos;ll be able to
              use YETI VPN untill {endDate}
            </div>
          </fieldset>
        </form>
        <div className='my-6 flex row justify-around'>
          <button
            className='yeti-button px-32 focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border bg-transparent yeti-button--v-outlined yeti-button--c-grey text-grey-darker border-grey block text-grey-darkest text-small mt-3 text-center'
            onClick={async () => {
              const result = await requests.cancelSubscription(subId, token, reason)
              console.log('cancelSubscription result', result)
              onConfirm()
              setAccount(null)
              navigate('/')
            }}
          >
            Yes
          </button>
          <button
            className='yeti-button px-32 focus:outline-none font-medium leading-normal align-bottom rounded select-none transition-colors ease-out duration-250 cursor-pointer border text-white yeti-button--v-contained yeti-button--c-blue bg-blue border-blue mt-3'
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>
  )
}
